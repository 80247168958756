import React from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"

import { Head, Layout, Section } from "../components"
import Content, { HTMLContent } from "../components/Content"

export const LegalPageTemplate = ({ content, contentComponent }) => {
  const PageContent = contentComponent || Content
  return (
    <Section>
      <PageContent content={content} />
    </Section>
  )
}

LegalPageTemplate.propTypes = {
  content: PropTypes.string,
  contentComponent: PropTypes.func,
}

const LegalPage = ({ data }) => {
  const { frontmatter, html } = data.markdownRemark
  return (
    <Layout>
      <Head title={frontmatter.title} />
      <LegalPageTemplate content={html} contentComponent={HTMLContent} />
    </Layout>
  )
}

LegalPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
      html: PropTypes.string,
    }),
  }),
}

export default LegalPage

export const pageQuery = graphql`
  query LegalPage {
    markdownRemark(
      frontmatter: { templateKey: { eq: "legal-page" } }
      html: {}
    ) {
      html
      frontmatter {
        title
      }
    }
  }
`
