import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"

const ContentStyled = styled.div`
  text-align: left;

  h1 {
    font-size: 45px;
    font-weight: 700;
    color: ${props => props.theme.colors.secondary};
    margin-bottom: 30px;
  }

  h2 {
    font-size: 24px;
    font-weight: 700;
    margin-bottom: 10px;
  }

  h2 ~ h2 {
    padding-top: 30px;
    margin-top: 30px;
    border-top: solid 1px ${props => props.theme.colors.darkGrey};
  }
  p {
    line-height: 1.7;
  }
`

export const HTMLContent = ({ content }) => (
  <ContentStyled dangerouslySetInnerHTML={{ __html: content }} />
)

const Content = ({ content }) => <ContentStyled>{content}</ContentStyled>

Content.propTypes = {
  content: PropTypes.node,
  className: PropTypes.string,
}

HTMLContent.propTypes = Content.propTypes

export default Content
